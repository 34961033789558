// Our main CSS
import '../css/app.css'
// layzyload and/or play/pause inline videos when they are in/out of view
const autoplaying_videos = document.querySelectorAll('video[playsinline]')

if (autoplaying_videos && autoplaying_videos.length) {
  import('./modules/inline-videos').then((module) => {
    module.default(autoplaying_videos)
  })
}

/**
 * Vue is ready for you, if you need it.
 */

/**
 * The following block of code automatically registers your Vue components.
 * It will recursively scan this directory for the Vue components and
 * automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 *
 * Feel free to remove this block if you don't like black magic fuckery,
 * or you want to register your Vue components manually.
 */
/*
 *** https://v3.vuejs.org/
 */
// interface ImportedModule {
//   name: string
//   default: object
// }
// const vueSingleFileComponents = import.meta.glob<ImportedModule>('./**/*.vue', {
//   eager: true
// })

// import { createApp } from 'vue'
// const app = createApp({})

// for (const path in vueSingleFileComponents) {
//   const importedModule = vueSingleFileComponents[String(path)]
//   const name = path.split('/').pop()?.split('.')[0]
//   if (name !== undefined && typeof name === 'string') {
//     app.component(name, importedModule.default)
//   }
// }
// app.mount('#vue-app')

/**
OR:

Load a js-module dynamically, if the parent element exists, in this example, a vue component. (The js-file wont load if html element with id #ExampleVueComponentWrapper does not exists)
*/

/**
const ExampleVueComponentWrapper = document.getElementById(
  'ExampleVueComponentWrapper'
)
if (ExampleVueComponentWrapper && ExampleVueComponentWrapper !== null) {
  import('./ExampleVueComponent/index.js')
    .then((module) => {
      module.default(ExampleVueComponentWrapper as HTMLElement)
    })
    .catch((err) => {
      console.log(err)
      // main.textContent = err.message;
    })
}
*/

/**
 * 🦄: Now do your magic.
 */

import { createApp } from 'vue'


import('./logoSlider').then((module) => {
  module.default()
})

const menu = createApp({

    data: () => ({
        active: null,
        mobileMenuActive: false,
        menuLabel: 'Meny'
    }),

    methods: {
        toggle(name) {
            if (this.active === name) {
                this.subMenuActive = null
                return this.active = null
            }

            this.active = name
        },
        toggleMobileMenu() {
            this.mobileMenuActive = !this.mobileMenuActive
            if (this.mobileMenuActive) {
                this.menuLabel = 'Stäng'
            } else {
              this.menuLabel = 'Meny'
            }
        },
        handleScroll() {
            const mobileNavScroll = this.$refs.mobileNavScroll;
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

            if (scrollTop > 300) {
              mobileNavScroll.classList.add('scrolled');
            }
            else {
              mobileNavScroll.classList.remove('scrolled');
            }
        },
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
})

menu.mount('#main-menu')